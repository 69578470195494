import axios from '@/common/axios'
import qs from 'qs'

export function addHandover (data) {
  return axios({
    method: 'post',
    url: '/warehousing/add',
    data: qs.stringify(data)
  })
}

export function deleteHandover (id) {
  return axios({
    method: 'delete',
    url: '/warehousing/delete/' + id
  })
}

export function updateHandover (data) {
  return axios({
    method: 'put',
    url: '/warehousing/update',
    data: qs.stringify(data)
  })
}

export function updateHandoverPurchase (data) {
  return axios({
    method: 'put',
    url: '/warehousing/purchase/update',
    data: qs.stringify(data)
  })
}

export function updateHandoverWarehouse (data) {
  return axios({
    method: 'put',
    url: '/warehousing/warehouse/update',
    data: qs.stringify(data)
  })
}

export function selectHandoverInfo (id) {
  return axios({
    method: 'get',
    url: '/warehousing/info/' + id
  })
}

export function selectHandoverList (query) {
  return axios({
    method: 'get',
    url: '/warehousing/list',
    params: query
  })
}
