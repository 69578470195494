<template>
  <div id="handover">
    <el-dialog
      :title="handoverFormTitle"
      width="1500px"
      :visible.sync="handoverDialogVisible"
      :close-on-click-modal="false"
      @close="handoverDialogClose"
    >
      <el-form
        ref="handoverFormRef"
        :model="handoverForm"
        inline
      >
        <el-form-item label="采购人签字" prop="purchaserResult">
          <el-radio-group v-model="handoverForm.purchaserResult" :disabled="checkRole(['ZJL'])">
            <el-radio :label="1">
              同意
            </el-radio>
            <el-radio :label="2">
              不同意
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="仓管员签字" prop="warehouseKeeperResult">
          <el-radio-group v-model="handoverForm.warehouseKeeperResult" :disabled="!checkRole(['ZJL'])">
            <el-radio :label="1">
              同意
            </el-radio>
            <el-radio :label="2">
              不同意
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <vxe-toolbar v-if="handoverFormTitle !== '入库交接详情' && !checkRole(['ZJL'])">
        <template #buttons>
          <vxe-button @click="insertRowEvent()">
            新增
          </vxe-button>
        </template>
      </vxe-toolbar>
      <vxe-table
        ref="xTable"
        border
        resizable
        show-overflow
        keep-source
        :data="handoverDetailList"
        :edit-config="{trigger: 'click', mode: 'cell'}"
      >
        <vxe-table-column type="seq" width="50" />
        <vxe-table-column
          field="productName"
          title="名称"
          width="150"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="spec"
          title="规格型号"
          width="100"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="quantity"
          title="数量"
          width="100"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="quality"
          title="物品质量"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="location"
          title="保管地点"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="storageTime"
          title="入库日期"
          width="150"
          :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
        />
        <vxe-table-column
          field="remarks"
          title="备注"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          v-if="handoverFormTitle !== '入库交接详情' && !checkRole(['ZJL'])"
          title="操作"
          width="100"
        >
          <template #default="{ row }">
            <template>
              <vxe-button @click="removeRowEvent(row)">
                删除
              </vxe-button>
            </template>
          </template>
        </vxe-table-column>
      </vxe-table>
      <div slot="footer">
        <el-button @click="handoverDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="handoverFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="入库交接单号">
        <el-input v-model="searchForm.storageNumber" placeholder="请输入入库交接单号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="handoverPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="storageNumber" label="入库交接单号" />
      <el-table-column prop="purchaser" label="采购人" />
      <el-table-column label="采购人签字">
        <template slot-scope="scope">
          <span v-if="scope.row.purchaserResult === 1">同意</span>
          <span v-if="scope.row.purchaserResult === 2">不同意</span>
        </template>
      </el-table-column>
      <el-table-column prop="warehouseKeeper" label="仓管员" />
      <el-table-column label="仓管员签字">
        <template slot-scope="scope">
          <span v-if="scope.row.warehouseKeeperResult === 1">同意</span>
          <span v-if="scope.row.warehouseKeeperResult === 2">不同意</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button> -->
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="handoverPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addHandover,
  deleteHandover,
  // updateHandover,
  updateHandoverPurchase,
  updateHandoverWarehouse,
  selectHandoverInfo,
  selectHandoverList
} from '@/api/purchase/handover'

export default {
  data () {
    return {
      handoverDialogVisible: false,
      handoverFormTitle: '',
      handoverForm: {
        id: '',
        inListDetailJson: '',
        purchaserResult: '',
        warehouseKeeperResult: ''
      },
      handoverPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        storageNumber: ''
      },
      handoverDetailList: []
    }
  },
  created () {
    selectHandoverList(this.searchForm).then(res => {
      this.handoverPage = res
    })
  },
  methods: {
    handoverDialogClose () {
      this.$refs.handoverFormRef.resetFields()
      this.handoverDetailList = []
    },
    handoverFormSubmit () {
      if (this.handoverFormTitle === '入库交接详情') {
        this.handoverDialogVisible = false
        return
      }
      this.$refs.handoverFormRef.validate(async valid => {
        if (valid) {
          this.handoverForm.inListDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.handoverFormTitle === '新增入库交接') {
            await addHandover(this.handoverForm)
          } else if (this.handoverFormTitle === '修改入库交接') {
            if (this.checkRole(['ZJL'])) {
              await updateHandoverWarehouse(this.handoverForm)
            } else {
              await updateHandoverPurchase(this.handoverForm)
            }
          }
          this.handoverPage = await selectHandoverList(this.searchForm)
          this.handoverDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.handoverFormTitle = '新增入库交接'
      this.handoverDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteHandover(row.id)
        if (this.handoverPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.handoverPage = await selectHandoverList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.handoverFormTitle = '修改入库交接'
      this.handoverDialogVisible = true
      selectHandoverInfo(row.id).then(async res => {
        this.handoverForm.id = res.id
        this.handoverForm.purchaserResult = res.purchaserResult
        this.handoverForm.warehouseKeeperResult = res.warehouseKeeperResult
        this.handoverDetailList = res.inListDetailList
      })
    },
    handleInfo (index, row) {
      this.handoverFormTitle = '入库交接详情'
      this.handoverDialogVisible = true
      selectHandoverInfo(row.id).then(async res => {
        this.handoverForm.id = res.id
        this.handoverForm.purchaserResult = res.purchaserResult
        this.handoverForm.warehouseKeeperResult = res.warehouseKeeperResult
        this.handoverDetailList = res.inListDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectHandoverList(this.searchForm).then(res => {
        this.handoverPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectHandoverList(this.searchForm).then(res => {
        this.handoverPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectHandoverList(this.searchForm).then(res => {
        this.handoverPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
#handover .el-dialog__body {
  margin: 0px 0px 20px 20px;
}
</style>
